import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/hanne/src/HS/strautmann.dev/src/templates/markdown-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Kontakt`}</h1>
    <p>{`Hannes Strautmann`}<br />{`
Zur Steinleite 9`}<br />{`
96163 Gundelsheim`}<br /></p>
    <p>{`Telefon: 0179 76 40 818`}<br />{`
E-Mail: `}<a parentName="p" {...{
        "href": "mailto:hannes@strautmann.dev"
      }}>{`hannes@strautmann.dev`}</a><br />{`
Threema: `}<a parentName="p" {...{
        "href": "https://threema.id/ZKW73JC9"
      }}>{`ZKW73JC9`}</a><br />{`
`}<a parentName="p" {...{
        "href": "https://www.xing.com/profile/Hannes_Strautmann"
      }}>{`Xing`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      